<template lang="pug">
.limit(data-test="cm-limit")
  .limit__header(:class="{'limit__header_active': +$route.query.course_exam_id === item.ID}")
    v-btn(
      icon
      v-if="examFilter"
      x-small
      color="white"
      @click="toggleCourseExamFilter"
    )
      v-icon mdi-image-filter-tilt-shift
    span.limit__title {{ item.licenseType }}
    v-btn(icon  small color="white" @click="$emit('click:edit', item)")
      v-icon(size="20") mdi-circle-edit-outline
  .limit__body
    .limit__item
      v-icon(color="primary" size="16").limit__item-icon mdi-clock-outline
      span.limit__text {{ item.timeFrom }} - {{ item.timeTo }}
    .limit__item
      v-icon(color="primary" size="16").limit__item-icon mdi-calendar
      span.limit__text(v-if="!item.exam_date_future_days") {{ item.dateFrom | formattedDateWithDDDInBeginning }} - {{ item.dateTo | formattedDateWithDDDInBeginning }}
      span.limit__text(v-else) {{ item.exam_date_future_days }} days
    .limit__item
      v-icon(color="primary" size="16").limit__item-icon mdi-map-marker
      span.limit__text {{ item.location }}
  .limit__footer
    cm-exam-counts(:item="item" withTooltip :hide-slots="hideSlotsInDynamicCourseDates")
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    examFilter: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    noAvaliableSlots() {
      return this.item.reservedCount === this.item.maxStudents
    },
    hideSlotsInDynamicCourseDates() {
      return !!this.item.exam_date_future_days;
    }
  },

  methods: {
    toggleCourseExamFilter() {
      if(+this.$route.query.course_exam_id === this.item.ID) {
        this.setCourseExamFilter()
        return
      }
      this.setCourseExamFilter(this.item.ID)
    },
    async setCourseExamFilter(ID) {
      await this.$router.replace({query: { ...this.$route.query, course_exam_id: ID}})
      this.$emit('click:filter')
    }
  },
  components: {
    cmExamCounts: () => import('./cm_exam/CMExamCounts.vue'),
    appCheckbox: () => import('@/components/global/Checkbox.vue'),
  }
}
</script>

<style lang="scss" scoped>
.limit {
  width: 145px;
  margin-bottom: 14px;
  &_online {
    &::v-deep label, &::v-deep input {cursor: initial !important}
  }
  &__header {
    background-color: $primary-color;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_active {
      background-color: $error-color;
    }
  }
  &__title {
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
  }
  &__body {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
  }
  &__text, .checkbox::v-deep label {
    color: $title-second;
    font-size: 12px;
    font-weight: 500;
  }
  &__item {
    line-height: 1;
    padding-top: 10px;
    &-icon {
      margin-right: 10px;
    }
  }
  &__count {
    font-size: 18px;
    color: $title-second;
    &_max {
      color: $end-color;
    }
  }
}

::v-deep .counts{
  font-size: 12px;
  border: 1px solid $border-color;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;

  &__item:last-child{
    border-bottom-right-radius: 4px;
  }
}
</style>
